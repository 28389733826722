@font-face {
  font-family: 'GILLSANS-Medium';
  src: url('/assets/font/GillSans-Medium.woff');
}
h2,h1,input{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.container {
  background-color: #f5eac1;
  width: 90%;
  max-width: 1600px;
  margin: auto;
  padding: 30px;
}
.box{background-color: white;padding: 30px;margin-bottom: 30px;}

h2.title {
  width: fit-content;
  display: -webkit-inline-box;
}
.title span{    color: white;
  background: black;
  padding: 10px 20px;
  border-radius: 33px;
  margin-right: 10px;}
.box-one{
  display: grid;
  grid-template-columns: 40% 60%;
  background-color: white;
}
.form-fild {
  display: block!important;
}
.center-logo {
    text-align: center;
    margin-top: 20px;
  }
  .logos{
    height: 50px;
    width: auto!important
  }
  .box-image {
    padding: 10px;
    text-align: center;
}
  .mat-mdc-card {
    box-shadow: none;
}
.content{
  box-shadow: none!important;
}
  
  .left-column {
    flex: 1;
    padding-top: 70px;
  }
  
  .right-column {
    flex: 1;
    padding: 20px;
  }
  
  .image-container {
    position: relative;
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .image-container img {
    width: 100%;
}
.border {
  border: 8px solid #f8b56d;
}

.no-border {
  border: none;
}
.input-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px; 
}
.form-field {
  width: 100%; 
}

.full-width {
  width: 100%; 
}
.two_column {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.three-column {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
button.Suscribir {
  margin: auto;
  display: block;
  background: black;
  color: white;
  border: none;
  font-size: 20px;
  padding: 16px 30px;
}
button.Suscribir:hover {
  
  background: #f8b56d;
  
}
@media (max-width:720px){
  .box-one{grid-template-columns: 100%;}
  .box{padding: 10px;}
  .input-grid,.two_column,.three-column{grid-template-columns: repeat(1, 1fr);}
}